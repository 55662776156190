<template>
<div>
    <section v-for="field in streamfield" :key="field.id">
        <WysiwygBlock v-if="field.type == 'wysiwyg'" :block="field" />
        <ZoomImage v-if="field.type == 'image' && field.value.allow_zoom" :block="field" />
        <ImageBlock v-else-if="field.type == 'image'" :block="field" />
        <HeadingBlock v-if="field.type == 'heading'" :block="field" />
        <BlockquoteBlock v-if="field.type == 'blockquote'" :block="field" />
    </section>
</div>
</template>

<script>
import WysiwygBlock from "@/components/blocks/Wysiwyg"
import ImageBlock from "@/components/blocks/Image"
import HeadingBlock from "@/components/blocks/Heading"
import BlockquoteBlock from "@/components/blocks/Blockquote"
import ZoomImage from "@/components/blocks/ZoomImage"

export default {
    name: 'StreamField',
    components: {
        WysiwygBlock,
        ImageBlock,
        HeadingBlock,
        BlockquoteBlock,
        ZoomImage,
    },
    props: {
        streamfield: Array
    }
}
</script>
